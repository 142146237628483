import { useState, useEffect } from 'react'

export default function useScrollThreshold(value) {
    const [exceeded, setExceeded] = useState(false)

    useEffect(() => {
        let scrolled = false
        let frameRequestID

        const touchScroll = () => {
            const dh = document.body.scrollHeight
            const { scrollY: sy, innerHeight: wh } = window

            setExceeded(sy > value * (dh - wh))

            scrolled = false
        }

        const onScroll = () => {
            if (!scrolled) {
                scrolled = true

                frameRequestID = window.requestAnimationFrame(touchScroll)
            }
        }

        window.addEventListener('scroll', onScroll)

        return () => {
            if (frameRequestID) {
                window.cancelAnimationFrame(frameRequestID)
            }

            window.removeEventListener('scroll', onScroll)
        }
    }, [value])

    return exceeded
}
