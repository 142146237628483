import { Navbar as PrestyledNavbar } from '@streamr/streamr-layout'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import styled from 'styled-components'

const Navbar = styled(PrestyledNavbar)`
    padding: 20px 24px;

    @media ${MqTablet} {
        padding: 16px 24px;
    }

    @media ${MqDesktop} {
        padding: 16px 24px;
    }
`

export default Navbar
