import React from 'react'
import styled from 'styled-components'
import Footer from './Footer'
import Nav from './Nav'
import GoTopButton from '~shared/GoTopButton'

const Inner = styled.div``

const UnstyledLayout = ({
    children = null,
    className,
    goTopButton = (
        <GoTopButton.Floater>
            <GoTopButton />
        </GoTopButton.Floater>
    ),
    nav = <Nav />,
    footer = <Footer />,
}) => (
    <>
        <div className={className}>
            {nav}
            {goTopButton}
            <Inner>{children}</Inner>
        </div>
        {footer}
    </>
)

const Layout = styled(UnstyledLayout)`
    background-color: ${({ $backgroundColor = '#fafafa' }) => $backgroundColor};
`

Object.assign(Layout, {
    Inner,
})

export default Layout
