import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { animateScroll as scroll } from 'react-scroll'
import { MqTablet } from 'streamr-ui/css/consts'
import useScrollThreshold from '~hooks/useScrollThreshold'
import ScrollEncourager, { Glyph } from '~shared/ScrollEncourager'

const UnstyledGoTopButton = (props) => {
    const onClick = useCallback(() => {
        scroll.scrollToTop({
            delay: 0,
            duration: 300,
            smooth: 'easeInOutQuad',
        })
    }, [])

    return (
        <ScrollEncourager {...props} as="button" type="button" onClick={onClick}>
            <Glyph />
        </ScrollEncourager>
    )
}

const GoTopButton = styled(UnstyledGoTopButton)`
    ${Glyph} {
        transform: rotate(180deg);
    }
`

const VisibilityBox = styled.div`
    transition: 0.3s;
    transition-delay: 0s, 0s, 0s;
    transition-property: visibility, opacity, transform;

    ${({ visible }) =>
        !visible &&
        css`
            opacity: 0;
            transform: translateY(25%);
            transition-delay: 0.3s, 0s, 0s;
            visibility: hidden;
        `}
`

const UnstyledFloater = (props) => {
    const visible = useScrollThreshold(0.5)

    return <VisibilityBox {...props} visible={visible} />
}

const Floater = styled(UnstyledFloater)`
    bottom: 32px;
    position: fixed;
    right: 32px;
    z-index: 9999;

    @media ${MqTablet} {
        bottom: 48px;
        right: 48px;
    }
`

Object.assign(GoTopButton, {
    Floater,
})

export default GoTopButton
