import React, { useCallback, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { scroller as scroll } from 'react-scroll'

const TargetID = 'ScrollEncouragerTarget'

const UnstyledGlyph = ({ className }) => (
    <svg
        className={className}
        width="19"
        height="25"
        viewBox="0 0 20 25"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.485 0a.75.75 0 0 0-.75.75v22.5a.75.75 0 0 0 1.5 0V.75a.75.75 0 0 0-.75-.75z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M.47 14.265a.75.75 0 0 0 0 1.061l8.485 8.485a.75.75 0 0 0 1.06 0l8.486-8.485a.75.75 0 0 0-1.06-1.06L9.484 22.22 1.53 14.265a.75.75 0 0 0-1.06 0z"
            fill="currentColor"
        />
    </svg>
)

export const Glyph = styled(UnstyledGlyph)``

export function scrollToTarget({ offset = 0 } = {}) {
    scroll.scrollTo(TargetID, {
        delay: 0,
        duration: 650,
        offset,
        smooth: 'easeInOutQuad',
    })
}

const UnstyledScrollEncourager = forwardRef(function UnstyledScrollEncourager(
    { className, offset = 0, style, noAppear },
    ref,
) {
    const onClick = useCallback(() => {
        scrollToTarget({
            offset,
        })
    }, [offset])

    return noAppear ? (
        <button type="button" className={className} onClick={onClick} ref={ref} style={style}>
            <Glyph />
        </button>
    ) : (
        <button
            $appear
            type="button"
            className={className}
            onClick={onClick}
            ref={ref}
            style={style}
        >
            <Glyph />
        </button>
    )
})

const ScrollEncourager = styled(UnstyledScrollEncourager)`
    align-items: center;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    box-sizing: border-box;
    color: #0324ff;
    cursor: pointer;
    display: flex;
    height: 48px;
    margin: 0;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 48px;

    ::before {
        background-color: white;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: 250ms ease-in-out transform;
        width: 100%;
        z-index: 0;
    }

    svg {
        display: block;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    :hover::before {
        transform: scale(1.25);
    }

    ${({ center }) =>
        !!center &&
        css`
            margin-left: auto;
            margin-right: auto;
        `}
`

Object.assign(ScrollEncourager, {
    TargetID,
})

export default ScrollEncourager
