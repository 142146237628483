import styled, { css } from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'

const Display = styled.div`
    ${({ mobile }) =>
        mobile != null &&
        css`
            display: ${mobile === true ? 'block' : mobile} !important;
        `}

    ${({ tablet }) =>
        tablet != null &&
        css`
            @media ${MqTablet} {
                display: ${tablet === true ? 'block' : tablet} !important;
            }
        `}

    ${({ desktop }) =>
        desktop != null &&
        css`
            @media ${MqDesktop} {
                display: ${desktop === true ? 'block' : desktop} !important;
            }
        `}
`

export default Display
