import React from 'react'
import {
    NavOverlay as PrestyledNavOverlay,
    LogoLink,
    HamburgerButton,
    Button,
    Menu,
} from '@streamr/streamr-layout'
import { NetworkMenu, AppsMenu, ResourcesMenu, NETWORK, APPS, RESOURCES, TOKEN } from './Nav'
import Display from '~shared/Display'
import { Link } from 'streamr-ui'
import Navbar from '~shared/Navbar'
import urls from '~utils/urls'
import styled from 'styled-components'
import { Link as LocalLink } from 'gatsby'

const MobileNav = () => (
    <Display as={NavOverlay} desktop="none">
        <NavOverlay.Head>
            <Navbar>
                <Navbar.Item spread>
                    {/* Preserve the space taken by the above logo. */}
                    <LogoLink as="div">
                        <svg viewBox="0 0 1 1" xmlns="http://www.w3.org/2000/svg" />
                    </LogoLink>
                </Navbar.Item>
                <Navbar.Item>
                    <HamburgerButton />
                </Navbar.Item>
            </Navbar>
        </NavOverlay.Head>
        <NavOverlay.Body>
            <NavOverlay.Link as={Link} href={urls.top}>
                Top
            </NavOverlay.Link>
            <NavOverlay.Dropdown label={NETWORK}>
                <NetworkMenu />
            </NavOverlay.Dropdown>
            <NavOverlay.Link as={Link} href={urls.token}>
                {TOKEN}
            </NavOverlay.Link>
            <NavOverlay.Dropdown label={APPS}>
                <AppsMenu />
            </NavOverlay.Dropdown>
            <NavOverlay.Dropdown label={RESOURCES}>
                <ResourcesMenu>
                    <Menu.Item as={LocalLink} to={urls.project.tokenMigration}>
                        Token migration
                    </Menu.Item>
                </ResourcesMenu>
            </NavOverlay.Dropdown>
        </NavOverlay.Body>
        <NavOverlay.Footer>
            <Button tag="a" kind="primary" size="big" href={urls.apps.hub}>
                Launch Hub
            </Button>
        </NavOverlay.Footer>
    </Display>
)

const NavOverlay = styled(PrestyledNavOverlay)`
    > div {
        z-index: 10000;
    }
`

export default MobileNav
