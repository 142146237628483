import React from 'react'
import { NewsletterConfirmation as PrestyledConfirmation } from 'streamr-ui'
import styled from 'styled-components'
import { useDiscardableEffect } from 'toasterhea'
import newsletterConfirmation from './newsletter_confirmation.png'

const Confirmation = styled(PrestyledConfirmation)`
    z-index: 9999;
`

export default function NewsletterConfirmation({ onReject }) {
    useDiscardableEffect()

    return <Confirmation open imageUrl={newsletterConfirmation} onCloseClick={onReject} />
}
