import { useCallback, useRef } from 'react'

export default function useRefs(...refs) {
    const refsRef = useRef()

    refsRef.current = refs

    return useCallback((element) => {
        refsRef.current.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(element)
            } else if (ref) {
                ref.current = element
            }
        })
    }, [])
}
