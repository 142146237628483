import React, { createContext, forwardRef, useContext, useRef } from 'react'
import { createGlobalStyle, keyframes } from 'styled-components'
import { MqDesktop } from 'streamr-ui/css/consts'
import useRefs from './useRefs'

const animUpwards = keyframes`
    0% {
        opacity: 0;
        transform: translateY(16px) translateZ(0px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) translateZ(0px);
    }
`

const animLeftwards = keyframes`
    0% {
        opacity: 0;
        transform: translateX(16px) translateZ(0px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px) translateZ(0px);
    }
`

const animRightwards = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-16px) translateZ(0px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px) translateZ(0px);
    }
`

const animNone = keyframes`
    0% {
        opacity: 0;
        transform: translateZ(0px);
    }
    100% {
        opacity: 1;
        transform: translateZ(0px);
    }
`

const CN_ANIMATE = 'Appear--animate'

const CN_FROZEN = 'Appear--frozen'

const CN_AWAIT_INTERSECTION = 'Appear--awaitIntersection'

const CN_IMMEDIATE = 'Appear--immediate'

// eslint-disable-next-line import/no-unused-modules
export const Css = createGlobalStyle`
    .${CN_ANIMATE} {
        animation: 0.5s ease-out 0s 1 normal both running ${animUpwards};
    }

    .${CN_ANIMATE}.Appear--leftwards {
        animation: 0.5s ease-out 0s 1 normal both running ${animLeftwards};
    }

    .${CN_ANIMATE}.Appear--rightwards {
        animation: 0.5s ease-out 0s 1 normal both running ${animRightwards};
    }

    .${CN_ANIMATE}.Appear--none {
        animation: 0.5s ease-out 0s 1 normal both running ${animNone};
    }

    .${CN_ANIMATE}.Appear--desktopRightwards {
        /* Leftwards by default. */
        animation: 0.5s ease-out 0s 1 normal both running ${animLeftwards};

        @media ${MqDesktop} {
            animation-name: ${animRightwards};
        }
    }


    .${CN_FROZEN}.${CN_FROZEN} {
        animation-play-state: paused;
    }
`

const { observe = () => {}, unobserve = () => {} } =
    typeof window === 'undefined' ? {} : window.AppearObserver

const AppearContext = createContext(false)

const Appear = forwardRef(function Appear(
    {
        id,
        tag: Tag = 'div',
        parentId,
        animate = false,
        delay,
        direction: directionProp,
        immediate,
        intersection: intersectionProp,
        forwardProps: { className: _className, ...forwardProps } = {},
        ...props
    },
    _ref,
) {
    const innerRef = useRef()

    const nested = useContext(AppearContext) === true

    const intersection = typeof intersectionProp === 'undefined' ? !nested : !!intersectionProp

    const direction = (() => {
        switch (directionProp) {
            case 'none':
            case 'leftwards':
            case 'rightwards':
            case 'desktopRightwards':
                return `Appear--${directionProp}`
            default:
                return undefined
        }
    })()

    const ref = useRefs(
        (element) => {
            if (typeof observe !== 'function' || typeof unobserve !== 'function') {
                return
            }

            if (innerRef.current && (!element || !intersection)) {
                unobserve(innerRef.current)
            }

            if (element && intersection) {
                observe(element)
            }
        },
        innerRef,
        _ref,
    )

    const className =
        [
            _className,
            animate && CN_ANIMATE,
            animate && CN_FROZEN,
            intersection && CN_AWAIT_INTERSECTION,
            immediate && CN_IMMEDIATE,
            direction,
        ]
            .filter(Boolean)
            .join(' ')
            .replace(/^\s+|\s+$/g) || undefined

    return (
        <AppearContext.Provider value>
            <Tag
                {...props}
                {...forwardProps}
                className={className}
                data-appear-id={id || undefined}
                data-appear-parent-id={parentId || undefined}
                data-appear-delay={delay}
                ref={ref}
            />
        </AppearContext.Provider>
    )
})

// eslint-disable-next-line import/no-unused-modules
export default Appear
