export const Layer = {
    Drawer: 'drawers',
}

export const Timeframe = {
    All: 'ALL',
    Month: '1M',
    Year: '1Y',
    Week: '7D',
    Day: '24H',
}

export const NetworkSubgraphUrl =
    'https://gateway-arbitrum.network.thegraph.com/api/8bcbd55cdd1369cadb0bb813d9817776/subgraphs/id/EGWFdhhiWypDuz22Uy7b3F69E9MEkyfU9iAQMttkH5Rj'

export const StreamrIndexerUrl = 'https://stream-metrics.streamr.network/api'
