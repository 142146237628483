import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

const UnstyledLink = forwardRef(function UnstyledLink(
    { className, newTab, noDecoration, noHover, ...props },
    ref,
) {
    if (newTab) {
        props.rel = 'noopener noreferrer'
        props.target = '_blank'
    }

    const { href } = props

    const Tag = href ? 'a' : GatsbyLink

    return <Tag {...props} className={className} ref={ref} />
})

const Link = styled(UnstyledLink)`
    &,
    :hover,
    :visited,
    :active,
    :focus {
        outline: 0;
        text-decoration: ${({ noDecoration }) => (!noDecoration ? 'underline' : 'none')};
    }

    ${({ noHover, noDecoration }) =>
        !noHover &&
        css`
            :hover,
            :focus {
                text-decoration: ${!noDecoration ? 'none' : 'underline'};
            }
        `}
`

export default Link
