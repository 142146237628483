import { toaster } from 'toasterhea'
import NewsletterConfirmation from '~shared/NewsletterConfirmation'
import { Layer } from '../consts'

let drawer = undefined

export async function openNewsletterConfirmation() {
    if (!drawer) {
        drawer = toaster(NewsletterConfirmation, Layer.Drawer)
    }

    try {
        await drawer.pop()
    } catch (e) {
        // We don't care.
    }
}
