import { createContext } from 'react'

const defaultContext = {
    pageKey: 'top',
    pagePath: '/',
}

const PageContext = createContext(defaultContext)

export default PageContext
